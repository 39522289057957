.FloatingSquares {
    background: #7899b4;
    position: relative;
    height: 100%;
    width: 100%;

    .heading {
        position: absolute;
        top: 54px;
        left: 213px;
        background: black;
        color: white;
        padding: 1.8em 2.9em 0.7em;
        min-width: 266px;
        transition: all 0.3s ease;

        &:not(.final) {
            animation: slideDown 0.6s ease-out;

            @keyframes slideDown {
                from {
                    opacity: 0;
                    transform: translateY(-30px);
                }

                to {
                    opacity: 1;
                    transform: translateY(0px);

                }
            }
        }

        &.final {
            opacity: 0;
        }

    }

    .square {
        border: 6px solid black;
        position: absolute;

        width: 513px;
        height: 193px;
        overflow: hidden;
        opacity: 0;

        &.settled {
            transform: translate(0, 0) !important;
            opacity: 1;
        }

        transition:all 0.4s ease-out;

        img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: -3px;
            background: white;
            left: -3px;
            z-index: 0;
        }

        .quote {
            position: absolute;
            background: #262626;
            padding: 1em 1.5em;
            text-align: left;
            color: white;
            font-size: 14px;
            color: rgb(255, 255, 255);
            line-height: 1.143;
            text-align: left;
            max-width: 180px;
    
        }
    }

   

    .std-button {
        position: absolute;
        bottom: 40px;
        right: 60px;
    }
}
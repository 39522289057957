.Bubble {
    background: rgba(236, 172, 0,
            0.9);
    color: white;
    box-shadow: 10.927px 13.023px 19.95px 1.05px rgba(0, 0, 0, 0.5);
    width: 200px;
    text-align: left;
    border-radius: 8px;
    padding: 1.2em;


    perspective: 500px;
    transition: all 0.5s;
    
    &.active{
        cursor:pointer; 
        &:hover{
            .flip-btn{
                opacity: 1;
                cursor: pointer;
                transform: scale(1.2);
            }
        }
    }

    &.not-flipped {
        transform: rotateY(0deg);
    }
    &.unflippable {
        .flip-btn{
            display: none;
            visibility: hidden;
        }
    }

    &.flipped {
        transform: rotateY(180deg);

        .text {
            transform: rotateY(180deg);
            animation: fade 0.3s ease;
        }

        .flip-btn {
            // bottom:auto;
            right: auto;
            // top: 4px;
            left: 2px;
        }
    }

    &.thought {
        border-radius: 18px;
        background: rgba(200, 200, 200, 0.9);
        color: #124c83;


    }

    &.initial {
        transform: scale(0);
        animation-name: expand-bounce;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }

    .flip-btn {
        // background: #00467f;
        background: none;
        // border-radius: 50%;
        outline: none;
        border: none;
        position: absolute;
        transition: all 0.3s ease;
        transform: scale(1);
        bottom: 4px;
        right: 2px;

        img {
            display: block;
        }

        &:hover {
            opacity: 1;
            cursor: pointer;
            transform: scale(1.2);
        }
    }

    &.flat {
        box-shadow: none;
        background: #ffffff;
        color: #00467f;
        border: 2px solid #00467f;

        .thinking-label, .says-label {
            font-style: italic;
            font-size: 0.8em;
            margin-bottom: 5px;


        }
    }

    .tail {

        &.top-right {
            .thought-bub {
                left: 24px;
                bottom: 100%;
                transform: rotateY(0deg) rotateZ(180deg);
            }

            .talk-bub {
                right: 22px;
                bottom: 100%;
                transform: rotateY(0deg) rotateZ(180deg);
            }

        }
        &.bottom-left {
            .talk-bub {
                left: 24px;
                top: 100%;
                transform: rotateY(0deg) rotateZ(0deg);
            }

            .thought-bub {
                right: 22px;
                top: 100%;
                transform: rotateY(180deg) rotateZ(0deg);
            }

        }

        &.right-center {
            .thought-bub {
                right: 100%;
                bottom: 50%;
                transform: rotateY(142deg) rotateZ(337deg);

            }

            .talk-bub {
                left: calc(100% - 5px);
                bottom: 50%;
                transform: rotateZ(270deg);

            }
        }

        &.left-center {
            .thought-bub {
                left: 100%;
                bottom: 50%;
                transform: rotateY(12deg) rotateZ(283deg);
                ;

            }

            .talk-bub {
                right: calc(100% - 5px);
                bottom: 50%;
                transform: rotateZ(90deg);

            }
        }

        .thought-bub {
            position: absolute;
            transition: all 0.3s 0.3s ease;
            transform: rotateY(0deg);
            opacity: 1;

            &.hidden {
                transform: rotateY(90deg) rotateZ(0deg);
                transition: none;
                opacity: 0;
            }
        }

        .talk-bub {
            position: absolute;
            transition: all 0.3s 0.3s ease;
            transform: rotateY(0deg);
            opacity: 1;

            &.hidden {
                transform: rotateY(90deg);
                transition: none;
                opacity: 0;
            }
        }
    }
}
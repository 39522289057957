html {
    background: #ccc;
}

.App {
    min-height: 100vh;
    text-align: center;

    * {
        font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    }

    h1,
    h2,
    h3 {
        font-weight: bolder;
        margin-bottom: 0.5em;
    }

    h1 {
        font-size: 2em;
        font-weight: 400;
        letter-spacing: -0.8px;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.17em;
    }

    p {
        font-size: 1em;
        margin-bottom: 1.2em;
        line-height: 1.2;
    }
    ul{
        list-style: disc;
        li{
            margin-left:2em;
            margin-right:1em;
            margin-bottom:0.5em;
        }
    }

    button:not(:disabled){
        cursor:pointer;
    }

    strong,
    b {
        font-weight: bold;
    }

    a{
        color:#124c83;
        &:visited, &:active{
            color:#124c83;
        }
        &:hover{
            color:black;
        }
    }

    i{
        font-style: italic;
    }

    .std-button {
        color: white;
        background: #982039;
        border: 1px solid #982039;

        outline: none;
        padding: 0.8em;
        min-width: 100px;
        text-transform: uppercase;
        position: relative;
        transition: all 0.3s ease;

        &:hover,
        &:focus {
            cursor: pointer;
            color: #982039;
            background: white;
            border: 1px solid #982039;
            font-weight: bolder;
            cursor:pointer;
        }

        &.secondary{
            color: #982039;
            background: white;
            border: 1px solid #982039;
            &:hover,
            &:focus {
                color: white;
                background: #982039;
                border: 1px solid #982039;
            }
    
        }

        &:disabled, &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
        &.round{
            border-radius:50%;
            padding:10px;
            min-width: initial;
            width: 45px;
            height:45px;
            display: flex;
            align-content: center;
            justify-content: space-around;

            img{
                display:block;
            padding-left:4px;

            }
            &:hover, &:focus{
                img{
                   filter: brightness(0.5) sepia(1) saturate(1106%) hue-rotate(322deg);
                }
            }
        }
        &.big{
            min-width:180px;
            min-height: 60px;
            font-size:1.2em;
        }
        &.reverse{
            transform:rotate(180deg);
        }
    }

    .Course {
        position: relative;
        top: 0;
        height: 700px;
        margin: auto;
        width: 1000px;
        background: white;
        text-align: left;
        overflow: hidden;

        &.big-header {
            .Header {
                height: 136px;
            }

            .content {
                height: 564px;
            }
        }

        .Header {
            position: relative;
            width: 100%;
            height: 106px;
            background: #f1f1f1;
            transition: all 0.3s ease;

            .text {
                position: absolute;
                width: 100%;
                text-align: center;
                top: 38px;

                h1 {
                    text-transform: uppercase;
                    font-size: 18px;
                    // font-family: 'Times New Roman', Times, serif;
                }
            }

            .blue-bar {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: #00467f;
                height: 20px;
            }
            .hamburger-menu{
                position: absolute;
                right:0;
                top:0;
                height:100%;
                display:flex;
                align-items: space-around;
                justify-content: center;
                padding:0.5em 1em;
                color:#00467f;
                .btn{
                    height: 30px;
                    min-width:30px;
                    flex-grow: 0;
                    background:none;
                    border:none;
                    outline:none;
                    margin-top:20px;
                    &:hover, &:focus{
                        cursor:pointer;
                        text-decoration: underline;
                    }
                }
                .menu{
                    ul{
                        
                        list-style: none;
                        button{ 
                            @extend a;
                            background:none;
                            border:none;
                            outline:none;
                            &:hover{
                                cursor:pointer;
                            }
                            &:focus{
                                outline:1px #00467f dotted;
                            }
                        }
                    }
                }
            }

        }

        .content {
            position: relative;
            height: 594px;
            margin-bottom: 200px;
        }

        .OutroModal{
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            .text{
                height:100%;
                display:flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding:10em 14em;
                a{
                   line-height: 2;
                }
            }
        }

        .QuickNav {
            display: flex;
            flex-direction: row;
            position: fixed;
            left: 0;
            bottom: 0;
            background: #222;
            padding: 5px;
            color: white;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            .mod {
                display: flex;
                flex-direction: column;
                margin: 1em 0;
                margin-right: 1em;

                .btns {
                    display: flex;
                    flex-wrap: wrap;
                    ;
                    flex-direction: row;
                }

                .current {
                    background: #ce723b;
                }
            }
        }


    }
}

@media screen and (max-width: 1200px), (max-height: 800px) {
    .QuickNav{
        display: none;
        visibility: hidden;
    }
  }
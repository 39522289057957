.SingleSelect { 
    height: 100%;;
    .question {
        position: absolute;
    height: 100%;;
    width:344px;

        background:#7899b4;
        h1 {
            position: absolute;
            background: black;
            color: white;
            padding: 0.5em 1.5em 5px;
            left: 130px;
            width:400px;
            font-weight: normal;
        }
        .question-body{
            color:#404040;
            position: absolute;
            left: 550px;
            width:340px;
            top:30px;
        }
    }
    img{
        position: absolute;
        z-index: 0;
        bottom:0;
        right:0;
    }
    .std-button{
        position: absolute;
        bottom:18px;
        right:24px;
    }
    .options {
        position: absolute;
        left:218px;
        top:100px;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        
        .option-btn {
            color: white;
            border-radius: 5px;
            background-color: rgb(12, 46, 78);
            box-shadow: 6px 0px 9px 0px rgba(16, 60, 102, 0.36);
            width: 449px;
            border:2px solid transparent;
            background:#0c2e4e;
            text-transform: none;
            font-weight: normal;
            min-height: 68px;
            text-align: left;
            padding-left:65px;
            padding-right:40px;
            margin:2px;
            color:#c9e1f5;
            transition:all 0.3s ease;
            position: relative;
            left:0;

            &:hover, &:focus, &.selected{
                font-weight: normal;
                border:2px solid white;
                left:-21px;
                min-height:75px;
                color:white;
                margin:5px 2px;
                cursor:pointer;
            }
            &.selected{
                background:#008080;
                color:white;
            }


        }
    }
}
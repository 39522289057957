.SelfReflection {
    display: flex;
    height: 100%;
    background: #f1f1f1;

    .left-blue-panel {
        background: #7899b4;
        height: 100%;
        width: 410px;
        flex-shrink: 0;
        padding-right: 35px;
        padding-top: 55px;

        h1 {
            padding: 0.4em 0.5em 0.4em 1.5em;

            background: black;
            color: white;
        }

        .body {
            color: white;
            padding: 36px 30px 0 72px;
        }
    }

    .questions {
        margin-bottom:40px; 
        padding: 1.5em;
        position: relative;
        display: flex;
    flex-direction: column;
    justify-content: space-around;
    width:100%;
        .outro{
            text-align: center;
        }
      

        .SRQuestion {
            margin-bottom: 15px;
            position: relative;

            .question-text {
                padding: 2px 2em;
                color: #00467f;
                font-weight: bold;
            }

            input,
            textarea {
                min-height: 2.5em;
                resize: none;
                width: -moz-available;          /* WebKit-based browsers will ignore this. */
                width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                width: fill-available;
                min-width:calc(100% - 2em);
                font-family: inherit;
                padding: 0.5em;
                border-radius: 8px;
                box-shadow: inset 2px 2px 2px #c2d3e0;  
                border:2px solid #f0f0f0;


                &:focus,
                 &:hover {
                    // box-shadow: none;
                    outline:none;
                    border-color:#3ca6fd;
                }
            }
        }

    }

    .std-button{
        position: absolute;
        bottom:30px;
        right:50px;
    }

    .setup{
        .square {
            border: 6px solid black;
            position: relative;
    
            width: 513px;
            height: 193px;
            overflow: hidden;
            transition:all 0.4s ease-out;
    
            img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                top: -3px;
                background: white;
                left: -3px;
                z-index: 0;
            }
    
            .quote {
                position: absolute;
                background: #262626;
                padding: 1em 1.5em;
                text-align: left;
                color: white;
                font-size: 14px;
                color: rgb(255, 255, 255);
                line-height: 1.143;
                text-align: left;
                max-width: 180px;
        
            }
        }
    }
}
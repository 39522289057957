.AudienceSelection {
    display: flex;
    height: 100%;
    justify-content: space-between;
    .disclaimer{
        position: absolute;
        width:100%;
        left:0;
        bottom:50px;
        color:black;
        // font-style: italic;
        font-weight: bold;
        font-size:12px;
        text-align: center;
    }
    .left-blue-panel {
        background: #7899b4;
        width: 410px;

        h1 {
            background: black;
            color: white;
            padding: 0.5em 2em;
            position: relative;
            left: 200px;
            margin-top: 36px;
        }

        .body {
            color: white;
            padding: 36px 72px;
        }
    }

    .options {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        .next-btn{
            align-self: flex-end;
            margin:0 25px 18px 0;
        }
        .btns {
            margin: 160px 90px 0 0;
            width: 340px;

            .option-btn {
                background: none;
                border-color: transparent;
                color: #00467f;
                font-weight: bold;
                display: block;
                width: 100%;
                text-align: left;
                padding-left: 60px;
                margin-bottom: 2px;
                text-transform: none;

                &:hover,
                &:focus {
                    border: 1px solid white;
                    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
                    color: #00467f;
                }

                &.selected {
                    background: #0c2e4e;
                    color: #00afac;

                    &:not(:hover) {
                        border-color: transparent;
                        box-shadow: none;

                    }
                }

            }

        }
    }

}
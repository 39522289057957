.Pager {
    background: #7899b4;
    height: 100%;
    width: 100%;
    position: relative;

    &.cleanup {
        button {
            pointer-events: none;
            opacity: 0 !important;
        }

        &.right-blue-panel {
            .blue-panel {
                transform: translateX(500px) !important;
            }

            .image-wrapper {
                transform: translateX(-1000px) !important;
            }
        }

        &.left-blue-panel {
            .blue-panel {
                transform: translateX(-500px) !important;
            }

            .image-wrapper {
                transform: translateX(-1700px) !important;
            }
        }

        &.full-page {
            .blue-panel {
                transform: translateX(-1000px) !important;
            }
        }
    }

    h1 {
        background: black;
        color: white;
    }

    .image-wrapper {
        opacity: 1;
        transform: translateX(0px);
        transition: all 0.4s ease-out;
        width: 52%;
        flex-shrink: 0;

        img {
        background:white;

            display: block;
            height: 100%;
        }
    }

    button {
        position: absolute !important;
        bottom: 42px;
        right: 49px;
        opacity: 0;
        animation: prev 0.4s 1.1s ease-in-out forwards;
    }


    .pager-body {
        margin-top: 60px;
        margin-left: 30px;
        color: white;
        padding-right: 80px;

        >* {
            opacity: 0;
            animation: prev 0.4s 0.2s ease-in-out forwards;
            &.tiny{
                font-size:10px;
                margin-right:130px;
            }
        }

        @for $i from 1 through 10 {
            >*:nth-child(#{$i}) {
                -webkit-animation-delay:(#{$i*0.15s + 0.4s});
                animation-delay:(#{$i*0.15s  + 0.4s});
            }
        }
    }




    //special layouts
    &.right-blue-panel {
        display: flex;
        justify-content: space-between;
        align-items: stretch;


        .image-wrapper {
            img {
                opacity: 0;
                animation: slideFromLeft 0.4s 0.2s ease-in forwards;
            }

        }

        .blue-panel {
            justify-self: flex-end;
            background: #7899b4;
            height: 100%;
            width: 48%;
            flex-shrink: 0;
            padding-left: 60px;
            padding-top: 55px;
            transform: translateX(0);
            transition: all 0.8s 0.2s ease;

            h1 {
                opacity: 0;
                animation: slideFromRight 0.7s 0.2s ease-in forwards;

                padding: 0.2em 0.8em;

            }
        }
    }

    &.left-blue-panel {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .image-wrapper {
            img {
                opacity: 0;
                animation: slideFromRight 0.4s 0.2s ease-in forwards;
            }


        }


        .blue-panel {
            justify-self: flex-end;
            background: #7899b4;
            height: 100%;
            width: 48%;
            flex-shrink: 0;
            padding-left: 60px;
            padding-top: 55px;
            transform: translateX(0);
            transition: all 0.4s ease;

            h1 {
                opacity: 0;
                animation: slideFromLeft 0.7s 0.2s ease-in forwards;

                padding: 0.2em 0.8em;

            }
        }
    }

    &.full-page {
        display: flex;
        justify-content: space-between;
        align-items: stretch;




        .blue-panel {
            justify-self: flex-end;
            background: #7899b4;
            height: 100%;
            width: 100%;
            flex-shrink: 0;
            padding-left: 60px;
            padding-top: 55px;
            transform: translateX(0);
            transition: all 0.4s ease;

            h1 {
                opacity: 0;
                animation: slideFromLeft 0.7s 0.2s ease-in forwards;

                padding: 0.2em 0.8em;

            }
        }
    }
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes prev {
    from {
        transform: translateY(30px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
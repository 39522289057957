.InterventionModel .Intro {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .std-button {
        position: absolute;
        bottom: 40px;
        right: 60px;
    }

    .cr-base {
        text-align: center;
        position: relative;
        z-index: 2;
        pointer-events: none;

        transition: all 0.3s ease;
        opacity: 1;

        &.inactive {
            opacity: 0;
        }

        &.active{
            >* {
                opacity: 0;
                transform: translateY(-5px);
                animation: slideReveal 0.3s ease-in forwards;

                @keyframes slideReveal {
                    from {
                        opacity: 0;
                        transform: translateY(-5px);
                    }

                    to {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }
        }

        .heading {
            background: black;
            color: white;
            padding: 0.3em 0.5em 0.1em;
            position: relative;
            margin: 14px 0 0;
            display: inline-block;
            animation-delay:0.3s;
           
            
        }

        .instructions {
            color: black;
            margin-top: 30px;
            font-size: 18px;
            // font-family: "Myriad Pro";
            color: rgb(0, 0, 0);
            line-height: 1.2;
            animation-delay:0.4s;


          


        }

        .copy {
            color: white;
            margin: 300px auto 0;
            max-width: 386px;
            text-align: left;
            animation-delay:0.5s;


            
        }


    }

    .circles {
        position: absolute;
        top: -120px;
        left: 0;
        height: calc(100% + 120px);
        width: 100%;


        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('./lines.png');
            background-position: 0 100px;
            background-repeat: no-repeat;
            transition: all 0.3s ease;
            opacity: 0;

            &:not(.hidden){
                animation:fadeIn 1s 0.5s ease-in forwards;
            }
            @keyframes fadeIn {
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }

            &.hidden {
                opacity: 0;
            }
        }

        .circle {
            transform: translate(0, 0);

            opacity: 1;
            .animation-wrapper{
                transform: scale(0);
                height:100%;
                width:100%;
                top:0;
                left:0;
                position: absolute;
                animation:expand-bounce 0.7s ease forwards;
                transform-origin: center center;
                @keyframes expand-bounce {
                    0% {
                        opacity:0;
                        transform: scale(0);
                    }
            
                    50% {
                        opacity:1;
                        transform: scale(1.1);
                    }
            
                    100% {
                        transform: scale(1);
                    }
                }
            }


            * {
                transition: all 0.3s ease;
            }



            .reveal {
                position: absolute;
                z-index: 3;

                h1,
                .body {
                    opacity: 0;
                    transform: translateY(-5px);
                    animation: slideReveal 0.3s 0.2s ease-in forwards;

                    @keyframes slideReveal {
                        from {
                            opacity: 0;
                            transform: translateY(-5px);
                        }

                        to {
                            opacity: 1;
                            transform: translateY(0px);
                        }
                    }
                }

                h1 {
                    background: black;
                    color: white;
                    padding: 0.3em 0.5em 0.1em;
                    position: relative;
                    margin: 14px 0 0;
                    display: inline-block;
                }

                .close-btn {
                    font-size: 18px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    border-radius: 50%;
                    width: 39px;
                    height: 39px;
                    outline: none;
                    border: solid 2px rgb(255, 255, 255);
                    transition:border 0.3s ease;
                    &:hover{
                        border-color:black;

                    }
                }

                .body {
                    font-size: 18px;
                    color: rgb(255, 255, 255);
                    line-height: 1.2;
                    text-align: left;
                    margin-top: 200px;
                    animation-delay: 0.4s;

                    width: 282px;
                    height: 125px;

                }
            }


            &.clickable {
                .inner:hover {
                    cursor: pointer;
                    transform: scale(1.2);
                }
            }

            &.current {
                .inner {
                    transform: scale(1.4);
                }

                .outer {
                    transform: scale(1.4) !important;
                    border-style: solid !important;
                }

                z-index: 1;
            }

            &.not-current {
                opacity: 0.2;
                pointer-events: none;
                z-index: 0;
            }

            .inner {
                border-width: 1.87px;
                border-color: rgb(255, 255, 255);
                border-style: solid;
                border-radius: 50%;
                box-shadow: 3.605px 6px 19.53px 1.47px rgba(0, 0, 0, 0.3);
                display: flex;
                align-items: center;
                justify-content: space-around;
                position: absolute;

                transform: scale(1);
                transform-origin: center center;

                .text {
                    font-size: 30px;
                    // font-family: "Myriad Pro";
                    color: rgb(255, 255, 255);
                    text-transform: uppercase;
                    line-height: 1.2;
                    text-align: center;
                }




            }

            &.red {
                .animation-wrapper{

                    animation-delay:0.4s;
                }
                .reveal {
                    top: 170px;
                    width: 280px;
                    left: 350px;

                    .body {
                        margin-top: 260px;
                    }
                    .close-btn {
                        font-size: 18px;
                        position: absolute;
                        top: -12px;
                        right: -186px;


                    }
                }

                .inner {
                    background-color: rgb(235, 60, 75);
                    left: 399px;
                    top: 271px;
                    width: 172.26px;
                    height: 172.26px;
                }

                .outer {
                    border-width: 2.5px;
                    border-color: rgb(235, 60, 75);
                    border-style: dotted;
                    border-radius: 50%;
                    background-color: rgba(235, 60, 75, 0.302);
                    position: absolute;
                    left: 227px;
                    top: 92px;
                    width: 516px;
                    height: 516px;
                    transform: scale(1);
                    opacity: 1;

                }

                &.current.outer {
                    background-color: rgba(235, 60, 75, 0.802);
                    transform: scale(1.2);
                    border-style: solid;
                }
            }

            &.blue {
                .animation-wrapper{

                    animation-delay:0.3s;
                }
                .reveal {
                    top: 165px;
                    width: 280px;
                    left: 20px;

                    .body {
                        margin-top: 220px;
                    }

                    .close-btn {
                        font-size: 18px;
                        position: absolute;
                        top: -12px;
                        right: -116px;


                    }
                }

                .inner {

                    background-color: rgb(23, 103, 115);
                    left: 20px;
                    top: 264px;
                    width: 137.26px;
                    height: 137.26px;
                }

                .outer {
                    border-width: 2.5px;
                    border-color: rgb(23, 103, 115);
                    border-style: dotted;
                    border-radius: 50%;
                    background-color: rgba(23, 103, 115, 0.302);
                    position: absolute;
                    left: -156px;
                    top: 111px;
                    width: 503px;
                    height: 503px;

                }

                &.current.outer {
                    background-color: rgba(23, 103, 115, 0.802);

                }


            }

            &.green {
                .animation-wrapper{
                    animation-delay:0.5s;

                }
                .reveal {
                    top: 200px;
                    right: 20px;
                    left: auto;

                    .body {
                        margin-top: 250px;
                        width: 400px;
                    }

                    h1 {
                        float: right;
                        margin-right: 60px;
                    }

                    .close-btn {
                        font-size: 18px;
                        position: absolute;
                        top: -12px;
                        right: 411px;


                    }
                    
                }

                .inner {

                    background-color: rgb(129, 220, 100);
                    left: 815px;
                    top: 300px;
                    width: 111.26px;
                    height: 111.26px;
                }

                .outer {
                    border-width: 2.5px;
                    border-color: rgb(129, 220, 100);
                    border-style: dotted;
                    border-radius: 50%;
                    background-color: rgba(129, 220, 100, 0.302);
                    position: absolute;
                    left: 623px;
                    top: 69px;
                    width: 503px;
                    height: 503px;

                }

                &.current .outer {
                    background-color: rgba(129, 220, 100, 0.502);
                    transform: scale(1.6) translateY(77px) !important;
                }

            }
        }

        &.inactive {
            .bg {
                opacity: 0;
            }

            .circle {
                transition: all 1s ease;

                &.phase-0 {
                    &.blue {
                        transform: translate(116px, -170px);
                    }

                    &.red {
                        opacity: 0.2;
                        transform: translate(116px, -70px);
                    }

                    &.green {
                        opacity: 0.2;
                        transform: translate(116px, 0px);
                    }
                }

                &.phase-1 {
                    &.blue {
                        transform: translate(-56px, -70px);
                        opacity: 0.2;
                    }

                    &.red {
                        transform: translate(-83px, -190px);
                    }

                    &.green {
                        opacity: 0.2;
                        transform: translate(116px, -70px);
                    }

                }

                &.phase-2 {
                    &.blue {
                        transform: translate(-206px, -70px);
                        opacity: 0.2;
                    }

                    &.red {
                        transform: translate(-223px, -40px);
                        opacity: 0.2;
                    }

                    &.green {
                        transform: translate(-325px, -190px);
                    }
                }
            }
        }
    }
}
.InterventionModel{
    position: relative;
    height: 100%;
    width:100%;
    background:#7899b4;
    .final-feedback{
        position: absolute;
        bottom:0;
        width:100%;
        left:0;
        background:white;
        display:flex;
        padding:1em;
        justify-content: space-evenly;
        .btns{
            display:flex;
            flex-direction: column;
            align-content: space-around;
            button{
                margin:5px;
            }
        }
    }
    .modal{
        background:rgba(0, 102, 185, 0.85);
        padding-bottom:50px;
        color:white;
        display:flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        z-index: 999;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity:0;
        padding:0 10em;
        .std-button{
            position: absolute;
            bottom:40px;
            right:60px;
        }
        animation: modalReveal 0.4s 1.4s ease-out forwards;
        @keyframes modalReveal {
            from{
                opacity:0;
            }
            to{
                opacity:1;
            }
        }
    }
    .film-strip{
        position: absolute;
        top:0px;
        width:100%;
        height:400px;
        margin-top:80px;
        transform:translateX(1000px);
        opacity: 0;
        animation:filmReveal 0.4s 0.8s ease-out forwards;
        @keyframes filmReveal {
            from{
                transform:translateX(1000px);
                opacity: 0;
            }
            to{
                transform:translateX(0px);
                opacity: 1;
            }
        }
        h1{
            background:black;
            color:white;
            padding:0.2em 0.5em;
            position: absolute;
            z-index: 2;
            top:-1.2em;
            right:285px;
            span{
                color:#159693;
            }
        }
        .scene{
            position: absolute;
            transition:all 0.8s ease-out;
            left:100%;
            width:100%;
            height:100%;
            &.current{
                left:0;
            }
            &.prev, &.far-prev{
                left:-100%;
            }
            &.next, &.far-next{
                left:100%;
            }
        }
    }
    .nav-buttons{
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:1em 200px;
        position: absolute;
        bottom:0;
        width:100%;
        left:0;
        transition:all 0.3s ease;
        &.inactive{
            bottom:-300px;
        }
        .line{
            position: absolute;
            width:calc(100% - 450px);
            left:220px;
            top:50%;
            border-bottom:2px solid #9db4c6;
        }
    }
    .q-scrim {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 70, 127, 0.5);
        mix-blend-mode: multiply;
        opacity: 0;
        animation: fade 0.4s 0.4s ease-out forwards;

    }
    .q-buttons{
        background:#ffffff;
        position: absolute;
        bottom:0;
        right:0;
        width:100%;
        display:flex;
        justify-content: center;
        flex-direction: row;
        transition:all 0.3s ease;
        padding:0.5em;
        .heading{
            position: absolute;
            bottom:calc(100% - 10px);
            left:20px;
            background:black;
            padding:1em;
            color:white;
            h1{
                margin:0;
            }
        }
        &.inactive{
            bottom:-300px;
        }
        .options{
            display:flex;
            justify-content: center;
            flex-direction: row;
        }
        .feedback{
            display:flex;
            padding:1em;
        }
        .action-btns{
            display:flex;
            justify-content: space-evenly;
            flex-direction: column;
            padding-right:1em;
        }
        .option-btn {
            display: block;
            position: relative;
            // background: white;
            border: 1px transparent solid;
            color: #00467f;
            font-size: 18px;
            width: 170px;
            min-height: 100px;
            margin: 1em 10px;
            transition: all 0.3s ease;
            transform: scaleX(1);

            &:not(:last-child)::after {
                content: '';
                position: absolute;
                right: -10px;
                top: 0;
                height: 100%;
                width: 0;
                border-right: 1px solid #b3b3b3;
            }

            &:focus,
            &:hover {
                background: #c1e2e1;
                cursor:pointer;
            }

            &.not-selected {
                transform: scaleX(0);
                margin: 0;
                flex-basis: 0;
            }
        }
    }
}
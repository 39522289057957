.ETSModel {
    position: relative;
    height: 100%;

    

    .img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        min-width: 100%;
        z-index: 0;
        background: #21303c;
        img{
            color:white;
        }
        .scrim {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: rgba(0, 70, 127, 0.5);
            mix-blend-mode: multiply;
            opacity: 0;
            animation: fade 0.4s 0.4s ease-out forwards;

        }
    }

    .green-scrim {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background:rgba(0, 175, 172, 0.7);
        opacity:0;
        animation: fade 0.4s 0.4s ease-out forwards;

    }

    .Part1,
    .Part2,
    .Part3 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

    }

    .Part1 {
        background: #f1f1f1;
        display: flex;
        justify-content: space-evenly;
        min-height: 210px;
        animation-delay: 4s;
        transform: translateY(350px);
        animation-name: pannelReveal;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;

        // box-shadow:inset 0 10px 5px rgba(0, 0, 0, 0.5);

        .question-text {
            position: absolute;
            bottom: 100%;
            width: 100%;
            text-align: center;

            h1 {
                margin: auto;
                display: inline-block;
                background: black;
                color: white;
                padding: 0.2em 1em 0;
            }
        }

        .options {
            display: flex;
            justify-content: space-evenly;
            margin: 1em;

            button {
                display: block;
                position: relative;
                // background: white;
                border: 1px transparent solid;
                color: #00467f;
                font-size: 24px;
                width: 220px;
                min-height: 100px;
                margin: 1em 10px;
                transition: all 0.3s ease;
                transform: scaleX(1);

                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    right: -10px;
                    top: 0;
                    height: 100%;
                    width: 0;
                    border-right: 1px solid #b3b3b3;
                }

                &:focus,
                &:hover {
                    background: #c1e2e1;
                    cursor:pointer;
                }

                &.not-selected {
                    transform: scaleX(0);
                    margin: 0;
                    flex-basis: 0;
                }
            }
        }

        .feedback {
            margin: 1em;
            .next-btn{
                margin-right: 1em;
                float: right;
            }
            .text {
                >* {
                    animation: fbreveal 0.5s ease-out forwards;
                    transform: scaleY(0);

                    @keyframes fbreveal {
                        from {
                            opacity: 0;
                            transform: scaleY(0) translateY(-5px);

                        }

                        to {
                            opacity: 1;
                            transform: scaleY(1) translateY(0);

                        }
                    }
                }

            }
        }
    }

    .Part2{
        background:#f1f1f1;

        .heading-text{
            position: absolute;
            bottom:100%;
            left:20px;
            h1{
                background:black;
                color:white;
                padding:0.2em 0.8em;
                margin:0;
                font-weight: normal;
               
            }
        }
        .next-btn{
            position: absolute;
            bottom:1em;
            right:2em;
        }
        .wrap{
            position: relative;
            display: flex;
            justify-content: space-evenly;
            min-height:180px;
            .text{
                padding:3em;
                width:530px;
            }
            .stats{
                background:#124c83;
                width:470px;
                position: relative;
                top:-5px;
                color:#cccccc;
                .stat{
                    padding:2em 3em 4em;
                }
            }
        }
    }

    .Part3{
        background:#f1f1f1;
        animation-delay: 1s;
        transform: translateY(350px);
        animation-name: pannelReveal;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        .heading{
            position: absolute;
            bottom:100%;
            left:20px;
            h1{
                background:black;
                color:white;
                padding:0.2em 0.8em;
                margin:0;
                font-weight: normal;
               
            }
        }
        .wrap{
            .group{
                height:100%;
            }
            display:flex;
            .fb-body{
                width:50%;
                padding:2em 3em;
            }
            .reflection{
                padding:2em 3em;
                text-align: right;
            }
            .thanks{
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                height: 85%;
                p{
                    font-size: 1.2em;
                }
                button{
                    position: absolute;
                    bottom: 1em;
                    right: 2em;
                }
            }

        }
        textarea {
            text-align: left;
            height: 70%;
            resize: none;
            width: -moz-available;          /* WebKit-based browsers will ignore this. */
            width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
            width: fill-available;
            font-family: inherit;
            padding: 0.5em;
            border-radius: 8px;
            box-shadow: inset 2px 2px 2px #c2d3e0;  
            border:2px solid #f0f0f0;


            &:focus,
             &:hover {
                // box-shadow: none;
                outline:none;
                border-color:#3ca6fd;
            }
        }
    }

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes expand-bounce {
        0% {
            transform: scale(0);
        }

        50% {
            transform: scale(1.25);
        }

        100% {
            transform: scale(1);
        }
    }

    /* Here the scale simply goes from 1 back to 0 */
    @keyframes shrink {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes pannelReveal {
        from {
            transform: translateY(350px);
        }

        to {
            transform: translateY(0px);
        }
    }

}